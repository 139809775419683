import { BasesActionTypes } from './constants';

type BasesAction = { type: string, payload: {} | string };

// common success
export const smsApiResponseSuccess = (actionType: string, data: any): BasesAction => ({
    type: BasesActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const smsApiResponseError = (actionType: string, error: string): BasesAction => ({
    type: BasesActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getBases = (payload): BasesAction => ({
    type: BasesActionTypes.GET_BASES,
    payload: payload,
});

export const createBase = (payload): BasesAction => ({
    type: BasesActionTypes.CREATE_BASE,
    payload: payload,
});

export const resetUploadingError = (): BasesAction => ({
    type: BasesActionTypes.RESET_CREATE_ERROR,
});

export const deleteBase = (payload): BasesAction => ({
    type: BasesActionTypes.DELETE_BASE,
    payload: payload,
});
