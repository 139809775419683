import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {BasesActionTypes} from './constants';
import {createBase as createBaseApi, deleteBase as deleteBaseApi, getBases as getBasesApi} from '../../helpers/';
import {smsApiResponseError, smsApiResponseSuccess} from './actions';
import {addNotification} from "../notifications/actions";

function* getBases(action) {
    try {
        const response = yield call(getBasesApi, action.payload);
        // mapper
        const { totalCount, basesList } = response.data?.bases;
        const pageCount = Math.ceil(totalCount / action.payload?.limit);
        yield put(smsApiResponseSuccess(BasesActionTypes.GET_BASES, { basesList, pageCount }));
    } catch (error) {
        yield put(smsApiResponseError(BasesActionTypes.GET_BASES, error));
    }
}

function* createBase(action) {
    try {
        yield call(createBaseApi, action.payload);
        yield put(smsApiResponseSuccess(BasesActionTypes.CREATE_BASE));
        yield put (addNotification({
            message: 'Новая база создана',
            variant: 'success',
        }))
    } catch (error) {
        yield put(smsApiResponseError(BasesActionTypes.CREATE_BASE, error));
        yield put (addNotification({
            message: 'Не удалось создать базу',
            variant: 'danger',
        }))
    }
}

function* deleteBase(action) {
    try {
        const response = yield call(deleteBaseApi, { id: action.payload.id, params: action.payload.params });
        const { totalCount, basesList } = response.data?.bases;
        const pageCount = Math.ceil(totalCount / action.payload.params?.limit);
        yield put(smsApiResponseSuccess(BasesActionTypes.GET_BASES, { basesList, pageCount }));
    } catch (error) {
        yield put(smsApiResponseError(BasesActionTypes.CREATE_BASE, error));
    }
}

export function* watchGetBases(): any {
    yield takeEvery(BasesActionTypes.GET_BASES, getBases);
}

export function* watchCreateBase(): any {
    yield takeEvery(BasesActionTypes.CREATE_BASE, createBase);
}

export function* watchDeleteBase(): any {
    yield takeEvery(BasesActionTypes.DELETE_BASE, deleteBase);
}

function* BasesSaga(): any {
    yield all([fork(watchGetBases), fork(watchCreateBase), fork(watchDeleteBase)]);
}

export default BasesSaga;
