// @flow
import {SmsSendingsActionTypes} from './constants';
import {statuses} from '../../pages/smska/sms/constants';

const INIT_STATE = {
    smsSendings: [],
    smsSendingToNew: null,
    isCreatedOrUpdatedSuccessfully: false,
    loading: false,
    error: null
};

type SmsSendiingsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean };

const SmsSendings = (state: State = INIT_STATE, action: SmsSendiingsAction): any => {
    switch (action.type) {
        case SmsSendingsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case SmsSendingsActionTypes.GET_SENDINGS: {
                    return {
                        ...state,
                        pageCount: action.payload?.data?.pageCount,
                        smsSendings: action.payload?.data?.sendingsList,
                        loading: false,
                    };
                }
                case SmsSendingsActionTypes.SEND_TO_ARCHIVE: {
                    return {
                        ...state,
                        smsSendings: state.smsSendings.filter(sending => sending.id !== action.payload?.data),
                        loading: false,
                    };
                }
                case SmsSendingsActionTypes.UPDATE_STATUS: {
                    const sendingIndex = state.smsSendings?.findIndex(
                        (sending) => sending._id === action.payload?.data.sms._id
                    );
                    return {
                        ...state,
                        smsSendings: [
                            ...state.smsSendings.slice(0, sendingIndex),
                            { ...state.smsSendings[sendingIndex], status: statuses[action.payload.data.sms?.newStatus] },
                            ...state.smsSendings.slice(sendingIndex + 1),
                        ],
                        loading: false,
                    };
                }
                case SmsSendingsActionTypes.CREATE_SENDING:
                case SmsSendingsActionTypes.UPDATE_SENDING: {
                    return {
                        ...state,
                        loading: false,
                        isCreatedOrUpdatedSuccessfully: true,
                        error: null,
                    };
                }
                default:
                    return { ...state };
            }

        case SmsSendingsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case SmsSendingsActionTypes.GET_SENDINGS:
                case SmsSendingsActionTypes.SEND_TO_ARCHIVE:
                case SmsSendingsActionTypes.GET_ABOUT_SENDING: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case SmsSendingsActionTypes.CREATE_SENDING:
                case SmsSendingsActionTypes.UPDATE_SENDING: {
                    return {
                        ...state,
                        uploadingError: action.payload.error,
                        isCreatedOrUpdatedSuccessfully: false,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case SmsSendingsActionTypes.GET_SENDINGS:
            return {
                ...state,
                smsSendings: [],
                loading: true,
            };

        case SmsSendingsActionTypes.UPDATE_STATUS:
        case SmsSendingsActionTypes.CREATE_SENDING:
        case SmsSendingsActionTypes.UPDATE_SENDING:
        case SmsSendingsActionTypes.GET_ABOUT_SENDING:
        case SmsSendingsActionTypes.SEND_TO_ARCHIVE:
            return {
                ...state,
                loading: true,
            };
        case SmsSendingsActionTypes.SET_SENDING_TO_NEW:
            return {
                ...state,
                smsSendingToNew: action.payload,
            };
        case SmsSendingsActionTypes.RESET_SUCCESSFULL_STATE:
            return {
                ...state,
                isCreatedOrUpdatedSuccessfully: false,
            };
        default:
            return { ...state };
    }
};

export default SmsSendings;
