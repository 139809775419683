export const BASES_SELECT_LABELS = {
    UPLOADED: 'загруженная',
    FLOW: 'поток',
};
export const BASES_SELECT = {
    UPLOADED: 'uploaded',
    FLOW: 'flow',
};

export const statuses = {
    0: 'на проверке',
    1: 'обработка',
    2: 'одобрена',
    3: 'рассылается',
    4: 'остановлена',
    5: 'считается',
    6: 'завершена',
    7: 'отклонена',
    8: 'отложена',
    9: 'на проверке',
};

export const timezones = [
    { value: '-12', label: 'GMT-12:00' },
    { value: '-11', label: 'GMT-11:00' },
    { value: '-10', label: 'GMT-10:00' },
    { value: '-9', label: 'GMT-9:00' },
    { value: '-8', label: 'GMT-8:00' },
    { value: '-7', label: 'GMT-7:00' },
    { value: '-6', label: 'GMT-6:00' },
    { value: '-5', label: 'GMT-5:00' },
    { value: '-4', label: 'GMT-4:00' },
    { value: '-3', label: 'GMT-3:00' },
    { value: '-2', label: 'GMT-2:00' },
    { value: '-1', label: 'GMT-1:00' },
    { value: '0', label: 'GMT 0:00' },
    { value: '+1', label: 'GMT+1:00' },
    { value: '+2', label: 'GMT+2:00' },
    { value: '+3', label: 'GMT+3:00' },
    { value: '+4', label: 'GMT+4:00' },
    { value: '+5', label: 'GMT+5:00' },
    { value: '+6', label: 'GMT+6:00' },
    { value: '+7', label: 'GMT+7:00' },
    { value: '+8', label: 'GMT+8:00' },
    { value: '+9', label: 'GMT+9:00' },
    { value: '+10', label: 'GMT+10:00' },
    { value: '+11', label: 'GMT+11:00' },
    { value: '+12', label: 'GMT+12:00' },
];
