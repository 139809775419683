import { BlacklistsActionTypes } from './constants';

type BlacklistsAction = { type: string, payload: {} | string };

// common success
export const blacklistsApiResponseSuccess = (actionType: string, data: any): BlacklistsAction => ({
    type: BlacklistsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const blacklistsApiResponseError = (actionType: string, error: string): BlacklistsAction => ({
    type: BlacklistsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getBlacklists = (payload): BlacklistsAction => ({
    type: BlacklistsActionTypes.GET_BLACKLISTS,
    payload: payload,
});

export const createBlacklist = (payload): BlacklistsAction => ({
    type: BlacklistsActionTypes.CREATE_BLACKLIST,
    payload: payload,
});

export const resetUploadingError = (): BlacklistsAction => ({
    type: BlacklistsActionTypes.RESET_CREATE_ERROR,
});
