import {APICore} from './apiCore';

const api = new APICore();
const baseUrl = '/api/v1/sms/';

function getSmsSendings(params: any): any {
    return api.getWithFilter(`${baseUrl}`, params);
}

function getAboutSmsSending(id: any): any {
    return api.get(`api/v1/sms/${id}`);
}

function sendSendingToArchive(id: any): any {
    return api.create(`${baseUrl}archive`, {id});
}

function updateStatus(params: any): any {
    return api.updatePatch(`${baseUrl}status`, params);
}

function createSmsSending(data: any): any {
    return api.create(`${baseUrl}create`, data);
}

function updateSmsSending(data: any): any {
    return api.update(`${baseUrl}`, data);
}

export { getSmsSendings, updateStatus, createSmsSending, updateSmsSending, getAboutSmsSending, sendSendingToArchive };
