import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';
import { users as mockAdminPageUsers } from '../mockData/mockAdminPage';

var mock = new MockAdapter(axios, { onNoMatch: 'passthrough' });

export function configureFakeBackend() {
    mock.onPost('/logout/').reply(function (config) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve([200, 'You have successfully logged out!']);
            });
        });
    });

    mock.onPost('/new-user/').reply(function (config) {
        return new Promise(function (resolve, reject) {
            setTimeout(function () {
                // get parameters from post request
                let params = JSON.parse(config.data);
                let newUser = { ...params, access: params.access.value };
                mockAdminPageUsers.push(newUser);

                resolve([200, newUser]);
            }, 1000);
        });
    });
}
