import { AgentsActionTypes } from './constants';

type AgentsAction = { type: string, payload: {} | string };

// common success
export const agentsApiResponseSuccess = (actionType: string, data: any): AgentsAction => ({
    type: AgentsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const agentsApiResponseError = (actionType: string, error: string): AgentsAction => ({
    type: AgentsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getAgents = (payload): AgentsAction => ({
    type: AgentsActionTypes.GET_AGENTS,
    payload: payload,
});

export const createAgent = (payload): AgentsAction => ({
    type: AgentsActionTypes.CREATE_AGENT,
    payload: payload,
});

export const updateAgent = (payload): AgentsAction => ({
    type: AgentsActionTypes.UPDATE_AGENT,
    payload: payload,
});

export const resetCreatingError = (): AgentsAction => ({
    type: AgentsActionTypes.RESET_CREATE_ERROR,
});
