import { APICore } from './apiCore';

const api = new APICore();

function getUsers(params: any): any {
    const baseUrl = '/api/v1/users/';
    return api.get(`${baseUrl}`, params);
}

function createUser(user): any {
    const baseUrl = '/api/v1/new-user/';
    return api.create(`${baseUrl}`, user);
}

export { getUsers, createUser };
