import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import {Provider} from 'react-redux';
import {configureStore} from './redux/store';
import {DevSupport} from '@react-buddy/ide-toolbox';
import {ComponentPreviews, useInitial} from './dev';
import {Notifications} from "./components/Notifications";

ReactDOM.render(
    <Provider store={configureStore({})}>
        <DevSupport ComponentPreviews={ComponentPreviews} useInitialHook={useInitial}>
            <App />
            <Notifications />
        </DevSupport>
    </Provider>,
    document.getElementById('root')
);
