import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = '/api/v1/agents/';

function getAgents(params: any): any {
    return api.get(`${baseUrl}`, params);
}

function createAgent(params: any): any {
    return api.create(`${baseUrl}`, params);
}

function updateAgent(params: any): any {
    return api.update(`${baseUrl}`, params);
}

export { getAgents, createAgent, updateAgent };
