import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = '/api/v1/blacklists/';

function getBlacklists(params: any): any {
    return api.get(`${baseUrl}`, params);
}

function createBlacklist(data: any): any {
    return api.create(`${baseUrl}`, data);
}

export { getBlacklists, createBlacklist };
