import {BlacklistsActionTypes} from './constants';

const INIT_STATE = {
    pageCount: 0,
    blacklists: [],
    loading: false,
};

type BlacklistsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean, uploadingError: string };

const Blacklists = (state: State = INIT_STATE, action: BlacklistsAction): any => {
    switch (action.type) {
        case BlacklistsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case BlacklistsActionTypes.GET_BLACKLISTS: {
                    return {
                        ...state,
                        blacklists: action.payload?.data?.blacklists?.blacklist,
                        loading: false,
                    };
                }
                case BlacklistsActionTypes.CREATE_BLACKLIST: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case BlacklistsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BlacklistsActionTypes.GET_BLACKLISTS: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case BlacklistsActionTypes.CREATE_BLACKLIST: {
                    return {
                        ...state,
                        uploadingError: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case BlacklistsActionTypes.GET_BLACKLISTS:
        case BlacklistsActionTypes.CREATE_BLACKLIST:
            return {
                ...state,
                loading: true,
            };
        case BlacklistsActionTypes.RESET_CREATE_ERROR:
            return {
                ...state,
                uploadingError: '',
            };
        default:
            return { ...state };
    }
};

export default Blacklists;
