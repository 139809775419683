import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = '/api/v1/flowbases/';

function getFlowbases(params: any): any {
    return api.get(`${baseUrl}`, params);
}
function getPids(params: any): any {
    return api.get(`${baseUrl}/pids`, params);
}

function createFlowbase(data: any): any {
    return api.create(`${baseUrl}`, data);
}

export { getFlowbases, getPids, createFlowbase };
