import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {BlacklistsActionTypes} from './constants';
import {createBlacklist as createBlacklistApi, getBlacklists as getBlacklistsApi} from '../../helpers/';
import {blacklistsApiResponseError, blacklistsApiResponseSuccess} from './actions';
import {addNotification} from "../notifications/actions";

function* getBlacklists(action) {
    try {
        const response = yield call(getBlacklistsApi, action.payload);
        const { blacklists } = response.data;
        yield put(blacklistsApiResponseSuccess(BlacklistsActionTypes.GET_BLACKLISTS, { blacklists }));
    } catch (error) {
        yield put(blacklistsApiResponseError(BlacklistsActionTypes.GET_BLACKLISTS, error));
    }
}

function* createBlacklist(action) {
    try {
        yield call(createBlacklistApi, action.payload);
        yield put(blacklistsApiResponseSuccess(BlacklistsActionTypes.CREATE_BLACKLIST));
        yield put (addNotification({
            message: 'Новый стоп-лист создан',
            variant: 'success',
        }))
    } catch (error) {
        yield put(blacklistsApiResponseError(BlacklistsActionTypes.CREATE_BLACKLIST, error));
        yield put (addNotification({
            message: 'Не удалось стоп-лист',
            variant: 'danger',
        }))
    }
}

export function* watchGetBlacklists(): any {
    yield takeEvery(BlacklistsActionTypes.GET_BLACKLISTS, getBlacklists);
}

export function* watchCreateBlacklist(): any {
    yield takeEvery(BlacklistsActionTypes.CREATE_BLACKLIST, createBlacklist);
}

function* blacklistsSaga(): any {
    yield all([fork(watchGetBlacklists), fork(watchCreateBlacklist)]);
}

export default blacklistsSaga;
