export const users = [
    {
        id: 1,
        name: 'first',
        email: 'first@ya.ru',
        password: '1111',
        access: 'user',
        balance: '945 634.45',
        cost: '0.35',
        permissions: {
            Streams: true,
            'Send to stream after click': false,
            'Departure to the take stream': false,
            Newsletters: false,
            'Editing the newsletter': false,
            Moderation: false,
            'User moderation': false,
            'Stream assignment': false,
            'Start/Stop mailing': false,
            'Loading domains': false,
            'Loading the number database': false,
            'Loading the blacklist database': false,
            'Unloading streams': false,
            'wmid statistics': false,
        },
    },
    {
        id: 2,
        name: 'second',
        email: 'second@ya.ru',
        password: '2222',
        access: 'user',
        balance: '9 455 634.45',
        cost: '0.45',
        permissions: {
            Streams: false,
            'Send to stream after click': true,
            'Departure to the take stream': false,
            Newsletters: false,
            'Editing the newsletter': false,
            Moderation: false,
            'User moderation': false,
            'Stream assignment': false,
            'Start/Stop mailing': false,
            'Loading domains': false,
            'Loading the number database': false,
            'Loading the blacklist database': false,
            'Unloading streams': false,
            'wmid statistics': false,
        },
    },
    {
        id: 3,
        name: 'third',
        email: 'third@ya.ru',
        password: '3333',
        access: 'user',
        balance: '95 634.45',
        cost: '0.35',
        permissions: {
            Streams: false,
            'Send to stream after click': false,
            'Departure to the take stream': true,
            Newsletters: false,
            'Editing the newsletter': false,
            Moderation: false,
            'User moderation': false,
            'Stream assignment': false,
            'Start/Stop mailing': false,
            'Loading domains': false,
            'Loading the number database': false,
            'Loading the blacklist database': false,
            'Unloading streams': false,
            'wmid statistics': false,
        },
    },
    {
        id: 4,
        name: 'fourth',
        email: 'fourth@ya.ru',
        password: '4444',
        access: 'user',
        balance: '5 645 634.37',
        cost: '0.35',
        permissions: {
            Streams: false,
            'Send to stream after click': false,
            'Departure to the take stream': false,
            Newsletters: true,
            'Editing the newsletter': false,
            Moderation: false,
            'User moderation': false,
            'Stream assignment': false,
            'Start/Stop mailing': false,
            'Loading domains': false,
            'Loading the number database': false,
            'Loading the blacklist database': false,
            'Unloading streams': false,
            'wmid statistics': false,
        },
    },
    {
        id: 5,
        name: 'fifth',
        email: 'fifth@ya.ru',
        password: '5555',
        access: 'user',
        balance: '9 634.75',
        cost: '0.80',
        permissions: {
            Streams: false,
            'Send to stream after click': false,
            'Departure to the take stream': false,
            Newsletters: false,
            'Editing the newsletter': true,
            Moderation: false,
            'User moderation': false,
            'Stream assignment': false,
            'Start/Stop mailing': false,
            'Loading domains': false,
            'Loading the number database': false,
            'Loading the blacklist database': false,
            'Unloading streams': false,
            'wmid statistics': false,
        },
    },
];
