import { AgentsActionTypes } from './constants';

const INIT_STATE = {
    agents: [],
    loading: false,
    creatingError: '',
};

type AgentsAction = {
    type: string,
    payload: { actionType?: string, data?: any, error?: string },
};
type State = { user?: {} | null, loading?: boolean, +value?: boolean, uploadingError: string };

const Agents = (state: State = INIT_STATE, action: AgentsAction): any => {
    switch (action.type) {
        case AgentsActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {
                case AgentsActionTypes.GET_AGENTS: {
                    return {
                        ...state,
                        agents: action.payload?.data?.agents,
                        loading: false,
                    };
                }
                case AgentsActionTypes.CREATE_AGENT: {
                    return {
                        ...state,
                        creatingError: 'success',
                        loading: false,
                    };
                }
                case AgentsActionTypes.UPDATE_AGENT: {
                    return {
                        ...state,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AgentsActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case AgentsActionTypes.GET_AGENTS:
                case AgentsActionTypes.UPDATE_AGENT: {
                    return {
                        ...state,
                        error: action.payload.error,
                        loading: false,
                    };
                }
                case AgentsActionTypes.CREATE_AGENT: {
                    return {
                        ...state,
                        creatingError: action.payload.error,
                        loading: false,
                    };
                }
                default:
                    return { ...state };
            }

        case AgentsActionTypes.GET_AGENTS:
        case AgentsActionTypes.CREATE_AGENT:
            return {
                ...state,
                loading: true,
            };
        case AgentsActionTypes.RESET_CREATE_ERROR:
            return {
                ...state,
                creatingError: '',
            };
        case AgentsActionTypes.UPDATE_AGENT:
            return {
                ...state,
                loading: true,
            };
        default:
            return { ...state };
    }
};

export default Agents;
