import {SmsSendingsActionTypes} from './constants';

type SmsSendingsAction = { type: string, payload: {} | string };

// common success
export const smsApiResponseSuccess = (actionType: string, data: any): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const smsApiResponseError = (actionType: string, error: string): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getSmsSendings = (searchParams): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.GET_SENDINGS,
    payload: searchParams,
});

export const getAboutSmsSending = (id): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.GET_ABOUT_SENDING,
    payload: id,
});

export const sendToArchive = (id): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.SEND_TO_ARCHIVE,
    payload: id,
});

export const updateStatus = (payload): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.UPDATE_STATUS,
    payload: payload,
});

export const setSending = (sendingData): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.SET_SENDING_TO_NEW,
    payload: sendingData,
});

export const createSmsSending = (payload): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.CREATE_SENDING,
    payload: payload,
});

export const updateSmsSending = (payload): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.UPDATE_SENDING,
    payload: payload,
});

export const resetSuccessfullState = (): SmsSendingsAction => ({
    type: SmsSendingsActionTypes.RESET_SUCCESSFULL_STATE,
    payload: null,
});
