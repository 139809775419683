import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {FlowbasesActionTypes} from './constants';
import {
    createFlowbase as createFlowbaseApi,
    getFlowbases as getFlowbasesApi,
    getPids as getPidsApi,
} from '../../helpers/';
import {flowbasesApiResponseError, flowbasesApiResponseSuccess} from './actions';
import {addNotification} from "../notifications/actions";

function* getFlowbases(action) {
    try {
        const response = yield call(getFlowbasesApi, action.payload);
        const { flowbases } = response.data;
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.GET_FLOWBASES, { flowbases }));
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.GET_FLOWBASES, error));
    }
}

function* getPids(action) {
    try {
        const response = yield call(getPidsApi, action.payload);
        const { pids } = response.data;
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.GET_PIDS, { pids }));
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.GET_PIDS, error));
    }
}

function* createFlowbase(action) {
    try {
        yield call(createFlowbaseApi, action.payload);
        yield put(flowbasesApiResponseSuccess(FlowbasesActionTypes.CREATE_FLOWBASE));
        yield put (addNotification({
            message: 'Новая потоковая база создана',
            variant: 'success',
        }))
    } catch (error) {
        yield put(flowbasesApiResponseError(FlowbasesActionTypes.CREATE_FLOWBASE, error));
        yield put (addNotification({
            message: 'Не удалось создать потоковую базу',
            variant: 'danger',
        }))
    }
}

export function* watchGetFlowbases(): any {
    yield takeEvery(FlowbasesActionTypes.GET_FLOWBASES, getFlowbases);
}

export function* watchGetPids(): any {
    yield takeEvery(FlowbasesActionTypes.GET_PIDS, getPids);
}

export function* watchCreateFlowbase(): any {
    yield takeEvery(FlowbasesActionTypes.CREATE_FLOWBASE, createFlowbase);
}

function* flowbasesSaga(): any {
    yield all([fork(watchGetFlowbases), fork(watchGetPids), fork(watchCreateFlowbase)]);
}

export default flowbasesSaga;
