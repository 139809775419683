import {NotificationsActionTypes} from './constants';

const INIT_STATE = {
    notifications: [],
};

const Notifications = (state = INIT_STATE, action) => {
    switch (action.type) {
        case NotificationsActionTypes.ADD_NOTIFICATION:
            return {
                ...state,
                notifications: [...state.notifications, {...action.payload, id: new Date().getTime()}],
            };
        case NotificationsActionTypes.REMOVE_NOTIFICATION:
            return {
                ...state,
                notifications: state.notifications.filter((n) => n.id !== action.payload),
            };
        default:
            return state;
    }
};

export default Notifications;
