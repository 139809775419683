// @flow
import { AdminActionTypes } from './constants';

type AdminAction = { type: string, payload: {} | string };

// common success
export const authApiResponseSuccess = (actionType: string, data: any): AdminAction => ({
    type: AdminActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});
// common error
export const authApiResponseError = (actionType: string, error: string): AdminAction => ({
    type: AdminActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});

export const getUsers = (): AdminAction => ({
    type: AdminActionTypes.GET_USERS,
    payload: {},
});

export const createUser = (user): AdminAction => ({
    type: AdminActionTypes.CREATE_USER,
    payload: user,
});
