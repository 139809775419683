import React from 'react';
import {Button, Toast, ToastContainer} from 'react-bootstrap';
import {useDispatch, useSelector} from "react-redux";
import {removeNotification} from "../redux/notifications/actions";

// const notification = {
//     id: 1,
//     // title: 'Успех',
//     message: 'Создана новая база',
//     time: 'сейчас',
//     variant: 'success',
//     delay: 5000
// }

export const Notifications = () => {
    const {notifications} = useSelector((state) => state.Notifications);
    const dispatch = useDispatch();

    const handleClose = (id) => {
        dispatch(removeNotification(id));
    };

    return (
        <ToastContainer position="bottom-end" className="p-3 position-fixed">
            {notifications.map((notification) => (
                <Toast
                    className={`bg-${notification.variant} ${!notification.title ? 'd-flex align-items-center text-white border-0 mt-1' : ''}`}
                    key={notification.id}
                    onClose={() => handleClose(notification.id)}
                    delay={notification.delay || 5000}
                    autohide>
                    {notification.title && <Toast.Header>
                        <strong className="me-auto">{notification.title}</strong>
                        <small>{notification.time}</small>
                    </Toast.Header>}
                    {notification.message && <Toast.Body>{notification.message}</Toast.Body>}
                    {!notification.title && <Button
                        variant=""
                        onClick={() => handleClose(notification.id)}
                        className="btn-close btn-close-white ms-auto me-2"
                    ></Button>}
                </Toast>
            ))}
        </ToastContainer>
    );
};
