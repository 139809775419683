// @flow
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';

import { createUser as createUserApi, getUsers as getUsersApi } from '../../helpers/';

import { authApiResponseError, authApiResponseSuccess } from './actions';
import { AdminActionTypes } from './constants';

function* getUsers() {
    try {
        const response = yield call(getUsersApi);
        const users = response.data?.users;
        yield put(authApiResponseSuccess(AdminActionTypes.GET_USERS, users));
    } catch (error) {
        yield put(authApiResponseError(AdminActionTypes.GET_USERS, error));
    }
}

function* createUser({ payload }) {
    try {
        const response = yield call(createUserApi, payload);
        const user = response.data;
        // NOTE - You can change this according to response format from your api
        yield put(authApiResponseSuccess(AdminActionTypes.CREATE_USER, user));
    } catch (error) {
        yield put(authApiResponseError(AdminActionTypes.CREATE_USER, error));
    }
}

export function* watchGetUsers(): any {
    yield takeEvery(AdminActionTypes.GET_USERS, getUsers);
}

export function* watchCreateUser(): any {
    yield takeEvery(AdminActionTypes.CREATE_USER, createUser);
}

function* adminSaga(): any {
    yield all([fork(watchCreateUser), fork(watchGetUsers)]);
}

export default adminSaga;
