export const FlowbasesActionTypes = {
    API_RESPONSE_SUCCESS: '@@flowbases/API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: '@@flowbases/API_RESPONSE_ERROR',

    GET_FLOWBASES: '@@flowbases/GET_FLOWBASES',
    GET_PIDS: '@@flowbases/GET_PIDS',

    CREATE_FLOWBASE: '@@flowbases/CREATE_FLOWBASE',
    RESET_CREATE_ERROR: '@@flowbases/RESET_CREATE_ERROR',
};
