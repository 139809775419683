import { APICore } from './apiCore';

const api = new APICore();
const baseUrl = '/api/v1/bases/';

function getBases(params: any): any {
    return api.get(`${baseUrl}`, params);
}

function createBase(params: any): any {
    return api.createWithFile(`${baseUrl}`, params);
}

function deleteBase({ params, id }): any {
    return api.delete(`${baseUrl}${id}`, params);
}

export { getBases, createBase, deleteBase };
